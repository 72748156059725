import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'assets/vendors/style';
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';

import MainApp from 'app/index';

import { setInitUrl } from '../actions/Auth';
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';
import { NotifyAlertProvider } from '../context/NotifyAlert';
import { LoadingProvider } from '../context/Loading';
import { MinutaEmbedModalProvider } from 'context/MinutaEmbedModalContext';
import { ProtocolCredentialProvider } from 'context/ProtocolCredentialContext';
import { SearchCaseProvider } from 'context/SearchCase';
import { SideNavMenuProvider } from 'context/SideNavMenu';
import { QueryClientProvider } from 'react-query';
import { dashboardQueryClient } from 'api/queryClients/dashboardQueryClient';
import { abstractDataUser, validateExperIpLogin } from 'util/isAdmin';
import { DialerContextProvider } from 'context/DialerControllerContext/DialerContext';
import DialerController from 'context/DialerControllerContext/components/Index';

const RestrictedRoute = ( { component: Component, authUser, ...rest } ) => {
	return (
		<Route
			{...rest}
			render={props =>
				authUser ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname : '/signin',
							state    : { from: props.location }
						}}
					/>
				)
			}
		/> );
};

const App = ( { locale, authUser, initURL, isDirectionRTL, setInitUrl } ) => {
	const match = useRouteMatch();
	const location = useLocation();
	const history = useHistory();
	const { default_url } = abstractDataUser( );

	useEffect( () => {
		window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
		if ( initURL === '' ) {
			setInitUrl( history.location.pathname );
		}
	}, [ initURL, setInitUrl, history.location.pathname ] );

	if ( location.pathname === '/' ) {
		if ( authUser === null ) {
			return <Redirect to={'/signin'} />;
		} else if ( initURL === '' || initURL === '/' || initURL === '/signin' ) {
			return <Redirect to={default_url ?? '/app/visao-geral'} />;
		} else {
			return <Redirect to={initURL} />;
		}
	}

	const applyTheme = createTheme( defaultTheme );

	if ( isDirectionRTL ) {
		applyTheme.direction = 'rtl';
		document.body.classList.add( 'rtl' );
	} else {
		document.body.classList.remove( 'rtl' );
		applyTheme.direction = 'ltr';
	}

	const currentAppLocale = AppLocale[locale.locale];

	return (
		<ThemeProvider theme={applyTheme}>
			<NotifyAlertProvider>
				<LoadingProvider>
					<SearchCaseProvider>
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
								<QueryClientProvider client={dashboardQueryClient}>
									<DialerContextProvider>
										{validateExperIpLogin() && <DialerController />}
										<SideNavMenuProvider>
											<MinutaEmbedModalProvider>
												<ProtocolCredentialProvider>
													<RTL>
														<div className="app-main">
															<Switch>
																<RestrictedRoute path={`${match.url}app`} authUser={authUser} component={MainApp} />
																<Route path='/signin' component={asyncComponent( () =>
																	import( 'app/routes/Signin' )
																)} />
																<Route component={asyncComponent( () => import( 'components/Error404' ) )} />
															</Switch>
														</div>
													</RTL>
												</ProtocolCredentialProvider>
											</MinutaEmbedModalProvider>
										</SideNavMenuProvider>
									</DialerContextProvider>
								</QueryClientProvider>
							</IntlProvider>
						</MuiPickersUtilsProvider>
					</SearchCaseProvider>
				</LoadingProvider>
			</NotifyAlertProvider>
		</ThemeProvider>
	);
};

const mapStateToProps = ( { settings, auth } ) => {
	const { sideNavColor, locale, isDirectionRTL } = settings;
	const { authUser, initURL } = auth;
	return { sideNavColor, locale, isDirectionRTL, authUser, initURL };
};

export default connect( mapStateToProps, { setInitUrl } )( App );
