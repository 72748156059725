import axios from 'axios';
import { verifyEnvUrl } from '../../util/verifyEnvUrl';


const PROD_URL = 'https://api.acordofechado.com.br/subClient';
const DEV_URL = 'https://us-central1-acordo-fechado-dev.cloudfunctions.net/subclientFunctionDev';
// const DEV_URL = 'http://localhost:8080';

const subclientsApi = axios.create( {
	baseURL: verifyEnvUrl( PROD_URL, DEV_URL ),
} );

subclientsApi.interceptors.request.use( async config => {
	const token = localStorage.getItem( 'af-token' );
	if ( token ) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
} );

export default subclientsApi;

