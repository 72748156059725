import axios from 'axios';
import { verifyEnvUrl } from 'util/verifyEnvUrl';

const prdURL = 'https://api.acordofechado.com.br/blocklist';
const devURL = 'https://us-central1-acordo-fechado-dev.cloudfunctions.net/blocklistApiDev';

// const devURL = 'http://localhost:8081';
const blockListApi = axios.create( {
	baseURL: verifyEnvUrl( prdURL,devURL )
} );

blockListApi.interceptors.request.use( async config => {
	const token = localStorage.getItem( 'af-token' );
	if ( token ) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
} );

export default blockListApi;
