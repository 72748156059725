import React, { useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SideNav } from 'containers/SideNav/index';

import {
	COLLAPSED_DRAWER,
	FIXED_DRAWER
} from 'constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';

import asyncComponent from '../util/asyncComponent';

import 'styles/global/GlobalStyle.css';
import { checkIsExpiredLoginToken } from '../util/utils';
import { isAdmin } from '../util/isAdmin';
import Header from 'newComponents/Header';
import { useSideNavMenu } from 'context/SideNavMenu';
import Error403 from 'components/Error403';

const RestrictedRoute = ( { component: Component, ...rest } ) => {
	const { menu, isLoading } = useSideNavMenu();
	const { path } = rest;

	if ( isLoading ) {
		return <></>;
	}

	let menuFinal = menu.reduce( ( acumulador, objetoAtual ) => {
		return acumulador.concat( objetoAtual, objetoAtual.son );
	}, [] );

	const isAllowed = !!menuFinal.find( item => item.url === path );

	return (
		<Route
			{...rest}
			render={props =>
				isAllowed ? (
					<Component {...props} />
				) : (
					<Error403 {...props} currentPath={path} />
				)
			}
		/> );
};

const App = ( props ) => {
	useEffect( () => {
		checkIsExpiredLoginToken();
		props.auth.isAdmin = isAdmin();

		if ( isIOS && isMobile ) {
			document.body.classList.add( 'ios-mobile-view-height' );
		} else if ( document.body.classList.contains( 'ios-mobile-view-height' ) ) {
			document.body.classList.remove( 'ios-mobile-view-height' );
		}
	}, [ props.auth.isAdmin ] );

	const {
		match,
		drawerType
	} = props;

	const drawerStyle = drawerType.includes( FIXED_DRAWER )
		? 'fixed-drawer'
		: drawerType.includes( COLLAPSED_DRAWER )
			? 'collapsible-drawer'
			: 'mini-drawer';

	return (
		<div className={`app-container ${drawerStyle}`} style={{ height: '100vh' }}>
			<SideNav {...props} />
			<div className="app-main-container">
				<div className={'app-header'}>
					<Header />
				</div>

				<main className="app-main-content-wrapper">
					<div className="app-main-content">
						<Switch>
							<RestrictedRoute
								path={`${match.url}/visao-geral`}
								component={asyncComponent( () => import( './routes/PaginaInicial/index' ) )}
							/>

							<RestrictedRoute
								path={`${match.url}/casos/update-em-lote`}
								component={asyncComponent( () =>
									import( './routes/BatchCaseUpdateV2' )
								)}
							/>

							<RestrictedRoute
								exact
								prevpath={props.location.pathname}
								path={`${match.url}/casos/:id`}
								component={asyncComponent( () =>
									import( './routes/Casos/DetalhesCasos/SingleCase' )
								)}
							/>

							<RestrictedRoute
								exact
								path={`${match.url}/casos`}
								component={asyncComponent( () =>
									import( './routes/Casos/DetalhesCasos' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/tarefas`}
								component={asyncComponent( () => import( './routes/Tarefas' ) )}
							/>

							<RestrictedRoute
								path={`${match.url}/subcliente/:id`}
								component={asyncComponent( () =>
									import( './routes/Clients/SubclientDetail' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/clients/:id`}
								component={asyncComponent( () =>
									import( './routes/Clients/ClientDetail' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/clients`}
								component={asyncComponent( () => import( './routes/Clients' ) )}
							/>

							<RestrictedRoute
								path={`${match.url}/cadastro/block-list`}
								component={asyncComponent( () => import( './routes/BlockList' ) )}
							/>

							<RestrictedRoute
								path={`${match.url}/cadastro/pessoas`}
								component={asyncComponent( () => import( './routes/Pessoas' ) )}
							/>

							<RestrictedRoute
								path={`${match.url}/pessoas/:id`}
								component={asyncComponent( () =>
									import( './routes/Pessoas/DetalhesPessoa' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/profile`}
								component={asyncComponent( () => import( './routes/Profile' ) )}
							/>

							<RestrictedRoute
								path={`${match.url}/editar-usuarios`}
								component={asyncComponent( () =>
									import( './routes/ManageUsers' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/cadastro/digital-certificate/editar/:id`}
								component={asyncComponent( () =>
									import( './routes/DigitalCertificate/Form/EditCertificateForm' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/cadastro/digital-certificate/novo`}
								component={asyncComponent( () =>
									import( './routes/DigitalCertificate/Form/NewCertificateForm' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/cadastro/digital-certificate`}
								component={asyncComponent( () =>
									import( './routes/DigitalCertificate/index' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/dashboard/negociadores`}
								component={asyncComponent( () =>
									import( './routes/CallCenterDashboard' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/dashboard/produtividade`}
								component={asyncComponent( () =>
									import( './routes/Dashboard/ProductivityDashboard' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/monitoramento-de-processos`}
								component={asyncComponent( () =>
									import( './routes/ProcessMonitoring' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/filas/:id`}
								component={asyncComponent( () =>
									import( './routes/InitialsQueueResults' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/filas`}
								component={asyncComponent( () =>
									import( './routes/QueueList' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/notificacoes`}
								component={asyncComponent( () =>
									import( './routes/Notifications' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/peticoes/:id`}
								component={asyncComponent( () =>
									import( './routes/ProtocolMonitoring/ProtocolQueueDetails' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/peticoes`}
								component={asyncComponent( () =>
									import( './routes/ProtocolMonitoring/ProtocolQueueList' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/cadastro/casosV2`}
								component={asyncComponent( () =>
									import( './routes/RegisterV2' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/captura-de-contatos/:id`}
								component={asyncComponent( () =>
									import( './routes/ContactCapture/ContactCaptureQueueDetails' )
								)}
							/>

							<RestrictedRoute
								path={`${match.url}/captura-de-contatos`}
								component={asyncComponent( () =>
									import( './routes/ContactCapture/ContactCaptureQueueList' )
								)}
							/>

							<Route
								exact
								path={`${match.url}/central-cliente`}
								component={asyncComponent( () =>
									import( './routes/HelpCenter' )
								)}
							/>

							<Route
								exact
								path={`${match.url}/403`}
								component={asyncComponent( () =>
									import( 'components/Error403' )
								)}
							/>

							<Route
								component={asyncComponent( () =>
									import( 'components/Error404' )
								)}
							/>
						</Switch>
					</div>
				</main>
			</div>
		</div>
	);
};

const mapStateToProps = ( { settings, auth } ) => {
	const { drawerType, navigationStyle, horizontalNavPosition, maintenance } = settings;

	return {
		maintenance,
		drawerType,
		navigationStyle,
		horizontalNavPosition,
		auth
	};
};

export default withRouter( connect( mapStateToProps )( App ) );
